/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
  }
  body {
    /* Margin bottom by footer height */
    margin-bottom: 60px !important;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
  }
  
  
  /* Custom page CSS
  -------------------------------------------------- */
  /* Not required for template or sticky footer method. */
  
  body > .container {
    padding: 60px 15px 0;
  }
  
  .footer > .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  code {
    font-size: 80%;
  }
  